
<script setup>
    import MCard from "mobile.vue.components.Card.vue";

    const props = defineProps({
        text: { type: String, required: true },
        icon: { type: String },
    });
</script>

<template>
    <MCard class="afm-card-btn" v-bind="$attrs">
        <div class="d-flex justify-content-center align-items-center border border-3" style="width: 3rem; height: 3rem; border-radius: 999px; background: #d6e3f0;">
            <i class="far fa-fw font-6" :class="icon" />
        </div>
        <span class="fw-medium">{{ text }}</span>
        <slot />
    </MCard>
</template>

<style scoped>
    .afm-card-btn {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0.5rem;
        color: rgb(10%, 10%, 10%) !important;
    }
</style>
